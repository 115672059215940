import React, { useEffect, useState } from 'react';
import './App.css';

const API_URL = 'https://blynk-api.luncky.com';
//const API_URL = 'http://127.0.0.1:8787';
const TOKEN_NAME = 'blynk_token';

function App() {
  const [token, setToken] = useState();
  const [listData, setListData] = useState([]);

  const query = (path, options = {}) => {
    const headers = token ? {Authorization: token} : {};
    return fetch(API_URL + path, Object.assign({}, {headers}, options))
      .then((resp) => resp.json());
  };

  const refresh = () => {
    query('/list')
      .then((list) => {
        setListData(list);
      });
  };

  const onSignIn = (event) => {
    const headers = {
      Authorization: event.detail.data.credential,
    };
    query('/token', {headers})
      .then(json => {
        event.detail.callback();
        window.sessionStorage.setItem(TOKEN_NAME, json.token);
        setToken(json.token);
      });
  };

  const onNewItem = () => {
    const name = window.prompt('輸入名稱');
    if (name !== null && name !== '')
      query('/new/' + encodeURIComponent(name), {method: 'POST'})
        .then((item) => {
          setListData((list) => {
            return [...list, item];
          });
        });
  };

  const onDeleteItem = (id) => {
    query('/item/' + id, {method: 'DELETE'})
      .then((item) => {
        setListData((list) => {
          return list.filter(i => i.id !== item.id);
        });
      });
  };

  const onRefresh = () => {
    refresh();
  };

  useEffect(() => {
    if (token)
      refresh();
  }, [token]);

  useEffect(() => {
    const token = window.sessionStorage.getItem(TOKEN_NAME);
    if (token)
      setToken(token);

    window.addEventListener('signin', onSignIn);
    return () => {
      window.removeEventListener('signin', onSignIn);
    };
  }, []);

  const renderList = () => {
    return (
      <>
        <table>
          <thead><tr><th>名稱</th><th>建立時間</th><th>連結</th><th>複製</th><th>刪除</th></tr></thead>
          <tbody>
          {
            listData.map(obj => {
              const url = window.location.origin + '/open.html?id=' + obj.id;
              return (
                <tr key={obj.id}>
                  <td>{obj.name}</td>
                  <td>{new Date(obj.createTime * 1000).toLocaleString()}</td>
                  <td><a href={url} target='_blank' rel='noreferrer'>{obj.id}</a></td>
                  <td><button onClick={() => {
                    navigator.clipboard.writeText(url)
                      .then(() => {
                        window.alert('Copied');
                      });
                  }}>複製</button></td>
                  <td><button onClick={() => {
                    if (window.confirm('確定刪除 "' + obj.name + '" 嗎？'))
                      onDeleteItem(obj.id);
                  }}>刪除</button></td>
                </tr>
              );
            })
          }
          </tbody>
        </table>
        <div><button onClick={onNewItem}>新增</button><button onClick={onRefresh}>刷新</button></div>
      </>
    );
  };

  return (
    <div className="App">
      <div className="header">Blynk</div>
      <div className="content">
        {token && renderList()}
      </div>
    </div>
  );
}

export default App;
